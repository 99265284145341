<template>
    <div class="all">
    <div class="box">
        <div class="shop">本店热销宝贝</div>
        <!-- <div><img class="img3" @click="goBack" src="./image/左箭头.png" alt=""></div> -->
        <button class="car" @click="goCar" >进入购物车</button>

        <div class="box1" v-for="item in list" :key="item.pr_id">
            <img class="img1" :src="item.pr_photo" alt="">
            <div class="name1">{{item.pr_name}}</div>
            <div class="name2">{{item.s_name}}</div>
            <div class="money">¥{{item.pr_price}}</div>
            <img class="img2" :src="item.s_photo" alt="">
            <button style="font-size:10px" >
               <router-link to="/index">购买</router-link>
            </button>
            <button @click="addToCar([{id:item.pr_id,name:item.pr_name,price:item.pr_price,photo:item.pr_photo,count:1}])" style="margin-left:25px">+购物车</button>

        </div>
    </div>
</div>
</template>

<script  >
import axios from 'axios'
import {mapMutations} from 'vuex'
    export default {
        
        data(){
            return{
                list:[]
            }
        },
        methods:{
            ...mapMutations(['changeId']),
            goBack(){
                this.$router.push('/homePage')
            },
            addToCar(value){
                this.changeId(value)
            },
            goCar(){
                this.$router.push('/car')
            },
        },
        created(){
            axios({
                url:"http://49.234.47.141:8112/SearchProduct?name=可乐",
                method:"GET"
            }).then(res=>{
                this.list=res.data.products
                console.log('11111')
                console.log(res.data.products);
                console.log(this.list)
            })
        }
    }
</script>

<style  scoped>
   .all{
    position: relative;
    background-color: lavenderblush;
}
.shop{
    font-size: 30px;
    margin: 0 8px -33px 60px;

}
   .box{
    position: absolute;
    /* margin-top: 50px; */
    padding-top: 20px;
    width:1144px;
    height:660px;
    margin: 0 auto ;
    border-radius: 15px;
    background-color: white;
    /* margin-bottom: 50px; */
    
    position: relative;
    border: 1px solid white;
   }
   .car{
    width: 100px;
    height: 40px;
    margin-left:939px;
    margin-top: 10px;
    border-radius: 5px;
    background-color:red ;
    float: left;
    z-index: 999;

   }
   .box .img3{
    width:40px;
    height: 40px;
    position: fixed;
   }
   .box1{
        float: left;
        width:200px;
        height:155px;
        margin-left: 60px;
        margin-top: 10px;
        border-radius: 5px;
        background-color: lavenderblush;
     }
     .img1{
        width:100%;
        height:70px;
        border-radius: 5px;
     }
     .img2{
        float: left;
        width:31px;
        height:31px;
        margin-left: 5px;
        width:20px;
        height:20px;
     }
     .name1{
        height: 32px;
        margin-left: 10px;
        font-size: 1px;
     }
     .name2{
        float: left;
        margin-left: 5px;
        margin-right: 10px;
     }
     .money{
        margin-left: 10px;
        font-size: 5px;
        margin-bottom: 5px;
     }
 
 
</style>