import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        product:[],
        // sum:0
    },
    mutations:{
        changeId(state,payload){
            state.product=[...state.product,...payload]
        },
        removeGood(state,arr){
            state.product=arr
        },
    }
})

export default store