<template>
    <div class="all">
       <h1>最后100米是一家社区外卖平台</h1>
       <h3>最后100米致力于为服务社区用户和商家，附近100米以内</h3>
       <h3>商家的产品，15分钟内可为用户高时效送达，是一个超高</h3>
       <h3>频次的社区外卖平台。</h3>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
  .all{
    margin: 100px auto;
    width: 50%;
    /* background-color: white; */
  }
  h1{
    text-align: center;
    font-size: 50px;
  }
  h3{
    margin-top: 50px;
    text-align: center;
    font-size: 25px;
  }

</style>