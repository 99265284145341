<template>
  <div class="all">
  <!-- 顶部区域 -->
   <div class="header-bar">
      <div @click="goRegister"  type="">进行注册</div>
      <div @click="goSee">了解最后100米</div>
      <div>联系客服：13956387340</div>
   </div>

    <!-- 搜索区域 -->
   <div class="header-search">
   <div class="arrive">最后100米</div>
   <div class="search" @click="goSearch" >
     <button class="button" @click="goSearch">搜索</button>
   </div>
      
   </div>
   <!-- 三栏区域 -->
   
   <div class="body-container">
   <!-- 第一栏 -->
     <div class="container-1">
       <div class="container-all">全部分类</div>
       <div @click="goSearch9(1)" class="container-classify">酒水饮料</div>
       <div @click="goSearch9(2)" class="container-classify">潮玩盲盒</div>
       <div @click="goSearch9(3)" class="container-classify">零食乳品</div>
       <div @click="goSearch9(4)" class="container-classify">日用百货</div>
       <div @click="goSearch9(5)" class="container-classify">雪糕冰淇淋</div>
       <div @click="goSearch9(6)" class="container-classify">调料粮油</div>
     </div>


     <!-- 第二栏 -->
     <div class="container-2">
       <div class="top">
          <div @click="goSearch9(1)" class="container-2-1">
            <div class="xiu">酒水饮料</div>
          </div>
          <div @click="goSearch9(2)" class="container-2-2">
            <div class="ke">潮玩盲盒</div>
          </div>
          <div @click="goSearch9(3)" class="container-2-3">
            <div class="xin">零食乳品</div>
          </div>
       </div>
       <div @click="goSearch9(4)" class="bottom1">
          <div class="container-2-4">
            <div class="xiang">日用百货</div>
          </div>
          <div @click="goSearch9(5)" class="container-2-5">
            <div class="su">雪糕冰淇淋</div>
          </div>
          <div @click="goSearch9(6)" class="container-2-6">
            <div class="ri"> 调料粮油</div>
          </div>
       </div>
     </div>


     <!-- 第三栏 -->
     <div class="container-3">
       <div class="container-3-1">
         <!-- <img class="img1" src="../components/image/350.jpg" alt=""> -->
         <button class="register" @click="goRegister">注册</button>
       </div>
       <div class="container-3-2">
       </div>
     </div>
   </div>


   <!-- 店铺区域 -->
   <div class="footer-container">
     <div class="top-shop">
       <div>最后一百米</div>
       <div> &nbsp;&nbsp;&nbsp;周边火热的商店</div>
     </div>
     <div class="footer-shop"> 
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/1.jpg" alt="">
         <div>联系电话:13956387340</div>
         <button @click="goSearch1" class="footer-shop-1-name">嘻牛先生></button>
       </div>
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/2.jpg" alt="">
         <div>联系电话:19930607340</div>
         <button @click="goSearch2" class="footer-shop-1-name">唐百便利店></button>
       </div>
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/3.jpg" alt="">
         <div>联系电话:17692167760</div>
         <button @click="goSearch3" class="footer-shop-1-name">力晟便利店></button>
       </div>
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/4.jpg" alt="">
         <div>联系电话:18330291287</div>
         <button @click="goSearch4" class="footer-shop-1-name">红番茄超市></button>
       </div>
     </div>
     <div class="footer-shop">
      <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/5.jpg" alt="">
         <div>联系电话:13932913068</div>
         <button @click="goSearch5" class="footer-shop-1-name">名优便利店></button>
       </div>
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/6.jpg" alt="">
         <div>联系电话:13356324785</div>
         <button @click="goSearch6" class="footer-shop-1-name">领鲜便利店></button>
       </div>
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/7.jpg" alt="">
         <div>联系电话:18031525665</div>
         <button @click="goSearch7" class="footer-shop-1-name">惠圆便利店></button>
       </div>
       <div class="footer-shop-1">
         <img style="width:280px;height:230px" src="../components/image/8.jpg" alt="">
         <div>联系电话:18031525615</div>
         <button @click="goSearch8" class="footer-shop-1-name">李国友超市></button>
       </div>
     </div>
   </div>


   <!-- 底部区域 -->
   <div class="bottom">
     <div class="bottom-1">
       <div>关于我们</div>
       <div @click="goSee"><a style=" text-decoration: none; " href="#">最后一百米</a></div>
       <div><a style=" text-decoration: none; " href="https://beian.miit.gov.cn/#/Integrated/index">冀ICP备2022018515号</a></div>
     </div>
     <div class="bottom-1">
       <div>加入我们</div>
       <div>招聘官网</div>
       <div>我想合作</div>
     </div>
     <div class="bottom-1">
       <div>关注我们</div>
       <div>获得支持</div>
       <div>一起努力</div>
     </div>
     <div class="bottom-1">
       <div>帮助中心</div>
       <div>一百米</div>
       <div>联系我们</div>
     </div>
     <div class="bottom-1">
       <div>发展规划</div>
       <div>展望前景</div>
       <div>发展历史</div>
     </div>
   </div>
  </div>
</template>

<script >
  export default {
    data(){
      return {
        num:''
      }
    },
    provide(){
      return {
        num:this.num
      }
    },
      methods:{
      goSee(){
        this.$router.push('/see100')
      },

      goRegister(){
        this.$router.push('/login')
      },
      goSearch(){
      this.$router.push('/search')
      },
      goSearch9(val){
         this.$router.push({path:'/pinlei',query:{val}})
      },
      goSearch1(){
         this.$router.push({path:'/shangDian',query:{id:'1'}})
      },
      goSearch2(){
         this.$router.push({path:'/shangDian',query:{id:'2'}})
      },
      goSearch3(){
         this.$router.push({path:'/shangDian',query:{id:'3'}})
      },
      goSearch4(){
         this.$router.push({path:'/shangDian',query:{id:'4'}})
      },
      goSearch5(){
         this.$router.push({path:'/shangDian',query:{id:'5'}})
      },
      goSearch6(){
         this.$router.push({path:'/shangDian',query:{id:'6'}})
      },
      goSearch7(){
         this.$router.push({path:'/shangDian',query:{id:'7'}})
      },
      goSearch8(){
         this.$router.push({path:'/shangDian',query:{id:'8'}})
      },
      
    },
  }
</script>

<style  scoped>

html,body{
  margin: 0;
  padding: 0;
  /* background-color: #999; */
}
.all{
  background-color: lavenderblush;
}
.header-bar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #F8F8F8;
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: space-around;
}
.header-search{
  width: 100%px;
  height: 157px;
  margin: 0 auto;
  padding: 0 0 35px;
  background-color: white;
  box-shadow: 0 5px 5px #ddd;
  position: relative;
}
.arrive{
  position: absolute;
  width: 280px;
  height: 122px;
  line-height: 122px;
  margin-top:40px ;
  margin-left: 160px;
  font-size: 40px;
  /* background-color: blanchedalmond; */
}
.search{
    position: absolute;
    width:600px;
    height:65px;
    background-color: white;
    margin: 0 auto;
    margin-top: 75px;
    margin-left: 510px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid red;
}
.button{
    width:80px;
    height:35px;
    background-color: red;
    border-radius: 5px 5px 5px 5px;
    float:right;
    margin-top: 12px;
    margin-right: 18px;
    line-height: 24px;
    text-align: center;
    font-size: 20px;
}
.body-container{
  width: 1200px;
  height: 425px;
  margin: 0 auto ;
  margin-top: 20px;
  /* background-color: white; */
  display: flex;
}
.container-1{
  width: 230px;
  height: 475px;
  margin-top: -50px;
  background-color: yellow;
  z-index: 999;
}
.container-all{
  margin: 5px 0 0 5px;
  opacity:3;
}
.container-classify{
  margin: 20px 0 50px 40px;
  font-size: 20px;
  color: #646464;
}
.container-2{
  width: 730px;
  height: 425px;
  margin: 0 0 10px 10px;
  
  /* background-color: green; */
  
}
.top{
  display: flex;
}
.container-2-1{
  width: 180px;
  height: 240px;
  margin: 10px 10px 10px 10px ;
  background-color: lightgoldenrodyellow;
  background-image: url('../components/image/81.jpg')  ;
  background-size: cover;
}
.xiu{
 margin: 14px 0 0 58px;
 font-size:15px;
}
.ke{
  margin: 18px 0 0 129px;
  font-size: 25px;
}
.xin{
  margin: 171px 0 0 21px;
  font-size: 15px;
}
.xiang{
  margin: 96px 0 0 92px;
  font-size: 20px;
}
.su{
  margin: 1px 0 0 103px;
  font-size: 15px;
}
.ri{
  margin: 75px 0 0 72px;
  font-size: 17px;
}
.container-2-2{
  width: 360px;
  height: 240px;
  margin: 10px 10px 10px 0;
  background-color: orange;
  background-image: url('../components/image/82.jpg');
  background-size: cover;
}
.container-2-3{
  width: 150px;
  height: 240px;
  margin-top: 10px;
  background-color: pink;
  background-image: url('../components/image/83.jpg');
  background-size: cover;

}
.bottom1{
  display: flex;
}
.container-2-4{
  width: 270px;
  height: 165px;
  margin: 0px 10px 10px 10px ;
  background-color: aqua;
  background-image: url('../components/image/11.jpg');
  background-size: cover;

}
.container-2-5{
  width: 270px;
  height: 165px;
  margin: 0px 10px 10px 0px ;
  background-color: aqua;
  background-image: url('../components/image/84.jpg');
  background-size: cover;

}
.container-2-6{
  width: 150px;
  height: 165px;
  background-color: pink;
  background-image: url('../components/image/85.jpg');
  background-size: cover;

}

.container-3{
  width: 238px;
  height: 425px;
  /* background-color: white; */
}
.container-3-1{
  width: 218px;
  height: 240px;
  margin: 10px 10px 10px;
  background-color: white;
  /* position: relative; */
  overflow: hidden;
  background-image: url('../components/image/11111111.jpg');
  background-size: cover;
}
.img1{
  /* position: absolute; */
  width: 200px;
  height: 200px;
  overflow: hidden;
  z-index: -1;
}
.register{
  float: left;
  margin: 191px 0 0 78px;
  width: 79px;
  height: 40px;
  border-radius: 5px;
  z-index: 999;
}
.container-3-2{
  width: 218px;
  height: 165px;
  margin: 0px 10px 10px 10px;
  background-image: url('../components/image/39.jpg');
  background-size: cover;
  background-color: white;
}

.footer-container{
  width: 1200px;
  min-height:400px ;
  margin: 30px auto;
  /* border: 1px solid black; */
  background-color: white;
}
.top-shop{
  width: 1190px;
  height: 44px;
  margin: 0 auto;
  padding-left: 10px;
  line-height: 44px;
  position: relative;
  background-color: red;
  border-radius: 5px 5px 0 0;
  display: flex;
}
.footer-shop{
  display: flex;
}
.footer-shop-1{
  width: 280px;
  height: 300px;
  margin: 2px 10px 5px 10px;
  /* background-color: red; */
}
.footer-shop-1-name{
  width: 90px;
  height: 30px;
  margin: 2px 0 0 170px;
  border-radius: 5px;
}

.bottom{
  width: 1200px;
  min-height:100px ;
  margin: 0 auto;
  /* background-color: gray; */
  display: flex;
}
.bottom-1{
  width: 174px;
  height: 90px;
  /* background-color: red; */
  padding-left: 50px;
  padding-top: 20px;
}
</style>