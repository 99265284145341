<template>
    <div class="box">
        <div class="top">注册</div>

        <div class="login">
           <img src="../components/image/手机.png" alt="">
           <div>请输入手机号</div>
           <input placeholder="+86" type="text" ref="content">
           <div><button @click="goLogin">立即注册</button></div>
           <div style="margin-left:-100px;margin-top:43px;margin-bottom:80px
           ">最后100米规则和服务保障</div>
           <input class="anniu" type="checkbox" v-model="checkedAll">
           <div>
           <a  href="/#/serve">服务协议</a> 
           
           <a  href="/#/privacy">隐私政策</a>
           </div>
           <div class="bottom"><a href="https://beian.miit.gov.cn/#/Integrated/index">冀ICP备2022018515</a></div>
        </div>
    </div>
</template>

<script >
    export default {
        data(){
            return{
                checkedAll:false,
                login:''
            }
        },
        methods:{
            goLogin(){
                 if(this.checkedAll && this.login.value.length==11){
                    this.$router.push('/homePage')
                }else{
                    alert('请阅读、勾选权限并输入正确电话号码')
                }
            },
            goServe(){
                this.$router.push('/serve')
            },
            goPrivacy(){
                this.$router.push('/privacy')
            }
        },
        mounted() {
        //    console.log(this);
           this.login = this.$refs.content
           console.log(this.login);
        },
        
    }
</script>

<style  scoped>
   .box{
    position: relative;
    width:1144px;
    height:660px;
    margin: 0 auto ;
    margin-bottom: 5px;
    background-color: white;
    border: 1px solid white;
   }
    .top{
    position:absolute;
    font-size: 70px;
    left:50%;
    margin-left: -78px;
    margin-top: 20px;
   }

   .login{
    /* background-color: red; */
    width:350px;
    height: 460px;
    margin: 200px 450px;
    font-size: 30px;
   }
    .login img{
        width:50px;
        height: 50px;
    }
    .login input{
        width: 300px;
        height: 20px;
    }
    .login button{
        width: 100px;
        height: 50px;
        background-color: blue;
        color: white;
        margin-left: 75px;
        margin-top: 20px;
        border-radius: 10px;
    }
    .login .anniu{
        float: left;
        margin-top: -96px;
        margin-left: 147px;
    }
    .login a:nth-child(1){
        margin-right: 90px;
    }
    a{
        text-decoration: none;
    }
    .login .bottom a{
        text-decoration: none;
        display: block;
        font-size: 15px;
        margin-top: 112px;
        margin-left: -300px;
    }
</style>