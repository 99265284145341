// 引入Vue
import Vue from 'vue'
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入App
import App from './App'
// 引入路由器
import router from './router'
// 二维码生成器
import VueQriously from 'vue-qriously'
// 引入Vuex
import store from '../src/store/store'
// import JiaLing from './components/JiaLing.vue'

Vue.use(VueQriously)





// 关闭Vue生产提示
Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  store,
  render: h => h(App), //将App组件放入容器中
  router: router
}).$mount('#app')