<template>
    <div class="box">
        <h1>佳玲闪送隐私政策</h1>
        <div><img @click="goLogin" src="./image/左箭头.png" alt=""></div>
        <div class="top">协议版本：【1.0】；发布/生效日期：【2023 年 4 月 26 日】</div>
        <div>
   <br><br> 佳玲闪送（以下或称“我们”）非常注重保护用户（“您”）的个人信息及隐私，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。我们希望通过本隐私政策向您清晰地介绍在使用我们的产品/服务时，我们如何处理您的个人信息，以及我们为您提供的访问、更正、删除和保护这些信息的方式。

 <strong> <br> <br> 本政策将帮助您了解以下内容：
 <br>一、我们如何收集和使用您的个人信息 
 <br>二、我们如何使用	Cookie 和同类技术
 <br>三、我们如何共享、转让、公开披露您的个人信息
 <br>四、我们如何保存和保护您的个人信息
 <br>五、您如何<u>管理您</u> 的个人信息
 <br>六、未成年人的个人信息保护
 <br>七、通知和修订
 <br>八、如何联系我们
 <br>九、附录

 <br><br>【特别提示】请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本
《隐私政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择。一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照本隐私政策处理您的相关信息。
如对本隐私政策有任何疑问，您可以通过本隐私政策“如何联系我们”中提供的方式与我们联系。 </strong>

<br><br>佳玲闪送的产品/服务是由唐山奶与<u>蜜科技</u> 有限公司（注册地址：河北省唐山市<u>路北区微家商厦</u> 01-1门343室）及其关联方提供者通过下述途径向您提供的产品/服务：包括但不限于佳玲闪送网站（mhsj100m.com）、佳玲闪送App等客户端以及相关<u>微信开放</u> 平台账号或小程序等。针对某些特定的产品/服务，我们还将制定单独的隐私政策，向您说明这些产品/服务的特殊政策。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。
本政策所称的佳玲闪送及其关联方是指唐山奶与<u>蜜科技</u> 有限公司及现有和/或未来设立的相关关联公司的单称或合称。
 
<strong> 请您注意，本政策不适用于您通过我们的产品/服务而接入的<u>其他第三</u> 方产品/服务(“其他第三方”，包括您的交易相对方、任何第三方网站以及第三 <u>方服务</u> 提供者等)，具体规定请参照该第三方的隐私政策或类似声明。</strong>

<br><br> <strong>一、我们如何收集和使用您的个人信息 </strong>
<br>在您使用我们的产品/服务时，您需要/可以选择授权我们收集和使用个人信息的场景包括：
<br>1、为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形，如 <u>您拒绝</u> 提供必要信息，您将无法正常使用我们的产品/服务；
<br>2、为了向您提供我们产品/服务的拓展功能，您可以选择授权我们收集、使用信息的情形，如 <u>您拒绝</u> 提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响 <u>您正常</u> 使用我们产品/服务的基本功能。
<br><strong> （一）您需要授权我们收集和使用个人信息的场景 </strong>
<br>我们会遵循正当、合法、必要的原则，出于本政策所述的下列目的收集和使用您的个人信息。
<br><strong>1、帮助您成为我们的用户 </strong>
<br>为遵守法律法规的要求，以及向您提供更便捷的服务，在 <u>您注册</u> 成为佳玲闪送用户时，您需要至少提供 <strong>手机号</strong> 码以创建佳玲闪送账号，并完善相关的 <strong>网络身份识别信息</strong> （ <strong>如头像、昵称及登录密码等</strong> ）； <u>如果您仅需</u> 使用浏览、搜索等功能，您无需注册成为我们的用户以及提供上述信息。
<br>在提供账号信息的过程中，如果您愿意额外补充如下个人信息，将有助于我们向您提供更为个性化的服务： <strong>包括您的性别、生日、常居地、家乡、喜好、家庭信息等。如果您不提供该等信息，不会影响您享受佳玲闪送服务的基本功能。
<br>如您选择授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息
（如头像、昵称、地区、性别等信息）与您的佳玲闪送账号进行绑定用于快捷登录，</strong> 我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。 
<br><strong>2、向您提供基于地理位置的信息展示</strong>
<br>为了向您提供周边吃喝玩乐及出行信息的展示和搜索服务，提升我们的服务表现、效率和功能，经您授权我们会收集您在使用我们服务过程中产生的相关信息，包 括：
<br> <strong>（1）	位置信息。当您通过系统授权开启移动设备的定位功能并使用基于位置提
供的服务时，我们会收集和使用您的位置信息以便为您推荐周边的生活服务</strong> （例
如您不需要手动输入所在地理位置就可获得相关服务，估算商家与您之间的实际距离方便您进行消费决策，为您推荐附近的服务品类和优惠信息等）。我们会使
用有关技术获取您的位置信息（准确度会有所不同），这些技术包括 IP	地址、
GPS 以及能够提供相关信息的 WLAN（如 Wi-Fi）接入点、蓝牙和基站、传感器信息。<strong>您使用服务中提供的信息中也可能包含相应地理位置信息，例如您在账号资料中设置的地区信息或您上传的图片、视频中的地理标记信息。您可以在移动
 
 设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。</strong>
<br> <strong>（2）	日志信息。</strong> 当您使用我们提供的产品/服务时，我们会收集您的浏览、搜索、
点击、收藏、添加购物车、交易、售后、关注、分享、发布等信息并作为有关网络日志进行保存，其中包括您的 IP 地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。
<br> <strong>（3）	传感器信息。</strong> 当您使用我们的产品/服务过程中，我们可能会收集您的传感
器信息以适配您的设备状态或在您选择参与活动时以识别您的动作，不同场景下的传感器类型可能不一致，我们仅会在具体需要的场景中才会使用。例如，在“扫一扫”场景下通过光线传感器判断黑暗环境，以便为您展示是否选择开启闪光灯或调整亮度；又如，在您播放视频或参与互动活动时，通过重力、加速度、陀螺仪等传感器来适配设备的横竖屏状态或者为您展示下一步的互动场景。
<br><br><strong>3、为您提供预订、下单、订单管理功能</strong>
您在佳玲闪送上预订/下单食品、饮料、日用百货、水果、美食、休闲娱乐、生活服务、电影票、丽人、运动健身、结婚、亲子、宠物、医疗等服务时，您需要根据所选择的服务类型提供 <strong>联系人信息（姓名、性别、电话号码等）、地址信息</strong> （如使用家政服务时）、服务偏好（如就餐人数等）等。
您在佳玲闪送上预订/下单机票、火车票、汽车票、民宿、跟团旅行、医疗、学生专享、出行等服务，或者办理手机卡或签证等业务时，您可能需要根据有关法律规定和相关方要求（票务及其代理方等）提供您的 <strong></strong> 电子邮箱地址（用于确认订单信息等）、 <strong>个人照片（用于初诊等）、同行人信息、紧急联络人信息</strong> 等。
您在佳玲闪送上预订/下单境内外酒店时，根据您选择的服务类型及相关方要求（酒店及其代理方等），您可能需要提供 <strong>联系人信息（入住人姓名、电话号码等）、电子邮箱地址（用于确认订单信息等）、订单备注等。</strong>
您在佳玲闪送上预订/下单境内外景点门票时，根据您选择的服务类型及相关方要求（景点及其代理方等），您可能需要提供您的<strong>实名身份信息（包括但不限于您的身份证、护照、港澳通行证等载明您身份的证件照片、复印件、号码等）、联系人信息（姓名、性别、生日、电话号码、微信号等）、电子邮箱地址（用于确认订单信息等）、订单备注等。</strong>
<br>为向您展示您账号的订单信息及便于您对订单信息的管理，我们会收集您在使用我们服务过程中产生的订单信息。
<br><strong>您在佳玲闪送上生成的订单中，将可能包含您的身份信息、联络信息、行程信息、住宿信息、支付信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供，</strong>
我们也将会在展示订单信息时，在保证信息可用性的基础上尽量去标识化处理。
<br> <strong>4、为您提供收藏、关注、分享、推荐功能</strong>
为了便于 <u>您管理</u> 自己的偏好，寻找具有共同爱好的用户或社区，获得更轻松的访问体验，与好友及第三方进行分享等，您可以收藏/关注/加入您感兴趣的商户/
 
用户/部落，也可以通过功能组件向第三方进行信息分享，我们会收集您在使用上述功能中产生的日志信息，用于在个人主页中向您展示、实现在线管理、发起共享以及向您推荐具有共同爱好的用户等。
<br><strong>5、为您提供涉及配送、代邮寄、跑腿功能</strong>
当您在佳玲闪送上使用相应功能涉及配送（例如外卖配送）/代邮寄（例如发票邮寄）
/跑腿（例如帮买帮送）服务时，您需要提供 <strong>取/收货人的姓名、性别（用于配送人员联络时称呼您，如刘女士）、手机号码、收货地址、门牌号等，</strong> 以便于您的订单能够安全准确送达并完成。您还可以选择为常用地址加注标签（如家、公司等），以便于此后的查找使用。
<br><strong>6、为您提供写点评、攻略、打卡等信息发布功能</strong>
您在佳玲闪送上主动对商家的产品/服务进行评价或发布其他内容（如分享心情、状态、签到、攻略等信息）时，我们将收集 <strong> <u>您发布</u> 的信息，并展示您的昵称、头像和发布内容等。</strong> 如您选择以匿名方式发布评价信息时，我们将不展示您的昵称、
头像。
<br> <strong>7、帮助您完成支付</strong>
您在佳玲闪送上进行订单支付时，您可以选择佳玲闪送及其关联方（如佳玲闪送支付）或者佳玲闪送的第三方合作机构（如 <u>微信支付</u> 、QQ 钱包、Apple Pay、支付宝等合作方）所提供的服务进行支付。我们需要收集您的 <strong>佳玲闪送订单信息、对账信息及其他法律要求的必要信息并与这些合作机构共享，</strong> 以 <u>确认您</u> 的支付指令并帮助您完成支付。如您使用的软件版本和硬件设备支持指纹或面容 ID 支付， <strong>您还可以在支付设置中选择开启或关闭对应的指纹或面容 ID 支付功能，</strong> 我们会在您 <u>支付</u> 过程中提供相应选项，<strong>同时您也可以选择密码、短信验证码等其他方式支付。支付的过程中，我们仅收集验证的结果，而不会直接收集您的指纹或面容 ID 信息。</strong>
<br><strong>8、为您提供直播服务</strong>
 <strong>您在开通直播服务时，根据法律法规的要求，可能需要通过“人脸识别”的方式进行实名认证，以 <u>验证您</u> 的身份。</strong> 在实名认证的过程中，我们和提供认证服务的第三方认证机构需要您提供 <strong>真实姓名、身份证号码及面部特征值信息。这些信息仅供实名认证及法律法规所规定的用途，未经您明示授权，不会用作其他目的。同时，特别提示您，这些信息是个人敏感信息，</strong> 您可以拒绝提供，拒绝提供可能会影响开通直播功能，但不会影响佳玲闪送其他功能的正常使用。
 
另外，您在使用直播服务时， <strong>我们将收集您直播的音视频信息，并展示您的昵称、头像及直播信息。</strong> 为了完成直播功能的正常服务，我们还会请求您授权 <strong>相机、麦克风权限。</strong> 您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用佳玲闪送其他功能。
<br><strong>9、为您提供客服和售后服务</strong>
当您与我们的 <u>客服取得</u> 联系时，我们的系统可能会记录您与 <u>客服之间</u> 的 <strong>通讯记录</strong> ， <strong>以及使用您的账号信息以便核验身份；</strong> 当您需要我们提供与您订单相关的客户服务时，我们可能会 <u>查询您</u> 的相关订单信息以便给予 <u>您适当</u> 的帮助和处理；当您需
要客服协助您 <u>修改</u> 有关信息（如配送地址、联系方式等）时，您可能还需要提供上述信息外的其他信息以便完成修改。
为了保证服务体验、处理服务纠纷，您在订单履行过程中拨打或者接听开启号码保护（隐私号）的电话时， <strong>您与商家、骑手间的通话信息可能会被录音。</strong> 当您与
商家、骑手间出现服务纠纷时，平台可依法调取并使用录音信息作为解决纠纷的参考依据。
<br> <strong>10、为您提供安全保障</strong>
为了保障您的账号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，<strong>在您访问及使用我们的产品/服务过程中，</strong>  <u>经您授权</u> 我们会获取您的 <strong>设备信息、网络状态信息，包括您使用的设备型号、硬件序列号、设备 MAC地址、唯一设备识别码（如  IMEI/MEID/IMSI/AndroidID/IDFA/IDFV/OAID、SIM卡 IMSI、ICCID 信息等设备标识符）、运行中进程信息、移动应用列表、传感器信息、WIFI 状态、电信运营商等软硬件特征信息。未经您的授权，我们不会提前获取以上信息。</strong>
为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或 <u>整合您</u> 的个人信息以及我们的关联方、合作伙伴取得您授权或者依法共享的信息，根据您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络 攻击等安全风险以及违反我们或关联方协议、政策或规则等行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接(“URL”)。
<br><strong>11、我们可能间接收集的个人信息</strong>
为完成身份核验，保障账号安全，确认交易状态及为您提供售后与争议解决等目的， <strong>经您授权</strong> 后我们可能会从第三方获取您的个人信息（例如通过您选择的交易对象和交易直接涉及的第三方信息系统、支付机构等收集与交易进度相关的您的交易、支付、出票、酒店预订、外卖预订等信息，以便于我们处理您的订单并保证服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为）。我们会依据法律法规的要求以及与第三方的约定， <u>经您授权</u> 后向佳玲闪送的关联方、合作伙伴获得您的有关信息，并对其信息来源的合法性进行确认后使用您的这些信息。
<br><strong>（二）您可以选择授权我们收集和使用个人信息的场景</strong>
为向您提供个性化、便捷的服务，您可以选择使用我们提供的拓展功能，我们会在符合法律规定并根据您具体授权的情况下收集并使用如下信息。这类信息将在
 
您选择的具体功能和业务场景中进行收集，如果您不提供这些信息，不会影响您使用基本功能。
<br> <strong>1、基于相机授权的拓展功能
<br> 您可以选择开启系统的相机权限，通过使用拍照、录视频、扫码等功能授权佳玲闪送访问您的相机，以便于您可以实现“扫一扫”、或者通过拍摄照片或录制视频等方式发布内容，</strong> 如果您需要录制并发布有声视频时，您还需开启麦克风权限。您还可以在扫码时点击授权闪光灯（单纯的闪光灯信息不属于个人信息），以便在光线暗淡时更好地进行扫码。相机功能您可以在系统权限中关闭，一旦关闭您将可能无法实现扫码功能，或者无法通过拍摄图片、视频等方式进行点评或互动，
<br> <strong>但不会影响您享受佳玲闪送服务的基本功能。</strong>
<br> <strong>2、基于相册授权的拓展功能
您可以选择开启系统的相册权限（部分机型中为存储权限），通过主动上传图片、视频等方式授权我们访问您的相册，以便于您通过上传照片或上传视频等方式发布内容。</strong> 我们会收集您选择上传发布的上述信息，此项功能您可以在系统权限中关闭，一旦关闭您将可能无法通过上传图片、视频等方式进行更换头像、发布带图点评等，<strong>但不会影响您享受佳玲闪送服务的基本功能。</strong>
<br> <strong>3、基于麦克风授权的拓展功能
您可以选择开启系统的麦克风权限，使用语音技术来实现语音搜索、智能点餐等语音交互功能。</strong> 我们会收集您在使用智能语音技术过程中录入的语音信息用于机器识别、在线交互并满足您的搜索或下单等需求（例如您在使用佳玲闪送外卖的“智能点餐”功能或者佳玲闪送的“按住说话”功能时，我们会根据您的需求进行周边生活服务信息的搜索、展示和下单）。此项功能您可以在系统权限中关闭，一旦关
<u>闭您将</u> 可能无法实现在线语音交互功能，<strong>但不会影响您享受佳玲闪送服务的基本功能。
<br>4、基于通讯录授权的拓展功能</strong>
您可以授权 <strong>通讯录</strong> 使用我们的服务创建交流与分享功能，并允许佳玲闪送获取您手机或其他第三方社交平台中的通讯录信息和公开信息（头像、昵称等），从而使您及您通讯录中同样授权开通此项功能的联系人，能够在佳玲闪送上分享有关内容（头像、昵称、评价信息等）以实现互动功能。您还可以授权我们读取您的通讯录信息用于为他人预定服务而无需再手动输入，例如为他人进行手机话费充值。此项
功能您可以在系统权限中关闭，一旦关闭您将可能无法实现基于通讯录的互动功能，<strong>但不会影响您享受佳玲闪送服务的基本功能。
<br>5、基于好友推荐的拓展功能</strong>
我们也可能基于您授权的通讯录信息、设备信息、好友关系、日志信息为您推荐好友或将您推荐给其他用户，帮助您拓展社交圈。此项功能您可以在隐私设置中进行关闭或解除好友关系，也可以自主管理允许他人查看的范围（例如关注的人、粉丝、分享时展示的头像昵称等）。一旦关闭您将可能无法实现与您的好友在美
团进行分享交流，<strong>但不会影响您享受佳玲闪送服务的基本功能。
<br>6、基于日历授权的拓展功能</strong>
 
<strong>当您使用直播订阅、榜单发布预定、行程提醒、秒杀活动提醒、签到提醒等功能时，我们会申请获取您的设备日历权限。</strong> 在您开启相关权限后，我们会访问您的日历，帮助您预约或调整已预约的直播订阅、榜单发布预定、行程提醒、秒杀活动提醒、签到提醒的时间，以在您预约的时间通过日历功能提醒您。<strong>拒绝授权将无法使用预约服务，但不会影响佳玲闪送其他功能的正常使用。
<br>7、基于运动与健身授权的拓展功能</strong>
当您使用步数统计、走路赚钱、骑行找车、寻找上车点等功能时，我们会申请获取您的运动与健身权限。在您开启相关权限后，我们会访问您的运动与健身权限，帮助您完成步数统计、走路赚钱、骑行找车、寻找上车点等功能。拒绝授权将无法使用上述服务，但不会影响佳玲闪送其他功能的正常使用。
<br><strong>8、基于剪切板授权的拓展功能
<br> 在您分享或接收被分享的信息、参加活动等情形下，我们需要在本地访问您的剪切板权限，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。我们仅在本地识别出剪切板内容属于佳玲闪送跳转、分享、活动联动等指令时才会将其上传我们的服务器。除此之外，我们不会上传您剪切板的其他信息至我们的服务器。</strong>
<br> 有些手机设备的系统会提示应用（App）正在读取剪切板，但该系统提示无法区分是本地处理还是上传，且由于统计方式的差异，不排除误报的可能。因此，如果您发现类似提示并有疑问，请与我们联系，以便我们定位问题。
<br><strong>（三）其他
<br> 您可以通过佳玲闪送为其他人预定各类产品/服务，并提供该实际服务接收人的有关信息，此前请您确保已取得相关人的授权。</strong>
<br> 如果我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。
<br><strong>（四）征得同意的例外
<br>请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：
<br>1、为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
<br>2、为履行法定职责或者法定义务所必需；
<br>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
<br>4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
<br>5、依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
<br>6、法律法规规定的其他情形。
<br>请注意，单独或与其他信息相结合无法识别您的身份或者与您直接建立联系的信息，不属于个人信息。如果我们将单独无法与任何特定个人建立联系的信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。
 
<br>（五）个人信息的使用规则</strong>
<br>为满足您的个性化需求，维护、改进我们的产品或服务质量，我们会在符合法律规定并根据您具体授权的情况下使用如下信息：
<br><strong>1、我们可能会收集您的订单信息、浏览及搜索信息、行为偏好（如您向我们提
供的膳食习惯、就餐环境要求、旅行计划、常用乘客/酒店/租车信息、航班舱位、  行李和票务选择等信息）、位置信息等，以及将您在佳玲闪送上使用某项服务中提供的信息与来自其他服务中的信息结合起来，进行综合统计、分析以形成用户画像，用来向您推荐或展示您可能感兴趣的产品/服务信息，或通过系统向您展示个性化的第三方推广信息。包括在 App 页面向您推送消息通知，为您提供智能推荐， 通过短信、App、电子邮件等给您发送推送消息、推广信息或展示商业广告，通过电话进行回访、向您提供信息咨询等服务或邀请您参与服务、产品或功能有关 的客户调研。例如将您在使用我们某项服务时收集的信息，用于在另一项服务中 向您展示个性化的内容或广告。</strong>
<br>2、我们可能会收集您在参与产品/服务调查时主动向我们提供的信息，以及您与我们的关联方、合作伙伴之间互动时提供的相关信息，以便于您追踪订单情况、发起用户投诉以及我们优化客户服务的质量与流程。
<br>3、我们可能将业务中收集的个人信息用于统计分析和改进运营，将已经去标识化无法识别您身份且不能复原的信息用于建立数据库并进行商业化利用。例如通过您所在的位置、偏好等进行统计分析，从而改进我们的产品、服务或营销计划；又如为改进我们系统而进行的技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。
<br><strong>（六）设备权限调用</strong>
<br>为确保相关业务功能的正常实现，我们需要根据具体的使用场景调用对应的必要权限，并在调用前向您弹窗询问。
<br> <strong>您可以在设备的设置中选择关闭部分或者全部权限，这可能导致对应的业务功能无法实现或者无法达到预期效果。

<br><br>二、我们如何使用 Cookie 和同类技术
<br>（一）Cookie 的使用</strong>
<br>为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为 Cookie 的小数据文件。Cookie会帮助您在后续访问我们网站时调用您的信息 ，<u>简化</u> 您填写个人信息(例如一键登录等)的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全等。我们不会将  Cookie 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除  Cookie。您可以清除计算机或移动设备上保存的所有 Cookie，您有权接受或拒绝 Cookie。大多数浏览器会自动接受 Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie；
 
<br>另外，您也可以清除软件内保存的所有 Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。
<br><strong>（二）网络 Beacon 和同类技术的使用</strong>
<br>除 Cookie 外，我们网页上常会包含一些电子图像(称为“单像素”GIF 文件或 “网络 Beacon”)等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些 Cookie。我们使用网络 Beacon 的方式有：
<br>1、我们通过在我们网站上使用网络 Beacon，计算用户访问数量，并通过访问 Cookie 辨认注册用户。
<br>2、我们通过得到的 Cookie 信息，可以在我们网站提供个性化服务。

<br><br><strong>三、我们如何共享、转让、公开披露您的个人信息
<br>（一）共享</strong>
<br>在我们向您提供产品/服务时，我们会采取严格的安全措施，由我们所完成的产品/服务中我们不会与最后100米以外的任何公司、组织和个人共享您的个人信息。但请您知悉，我们提供的产品/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息，以保障和优化我们为您提供的产品/服务，此类情况包括：
<br> <strong>1、提供平台服务。</strong> 我们可能会作为电子商务平台向您提供产品/服务，<strong>如您在我
们的平台上使用商家或其他合作方提供的产品/服务，我们可能会与相应产品/服务的提供方共享您的必要个人信息，</strong> 以便于您能够正常使用相应的浏览、下单、交易、支付等功能。如您在我们的平台上预订境外产品/服务时，<u>经您同意</u> ，我
们可能会将您的信息提供给境外商家或合作的第三方，如预订人姓名、联系方式等信息，以便为您提供产品/服务预订、身份核验、信息通知及订单售后等服务。 
<br><strong>2、提供统一管理服务。</strong> 如您使用我们的账号登录和使用我们关联公司和其他第三方所提供的产品/服务，我们可能会共享您的个人信息。我们只会共享必要的
个人信息以便向您提供一致化的服务和统一管理，例如在我们的产品/服务内查看订单并进行管理，<strong>如果我们共享您的个人敏感信息或者关联方改变个人信息的使用目的，将再次征求您的授权同意。</strong>
<br><strong>3、提供必要的合作服务。</strong> 仅为实现本政策中声明的目的，我们的某些服务将由
业务合作伙伴提供。为保障为您提供的服务顺利完成，我们可能会将您的个人信息共享给我们的合作伙伴，包括配送业务、技术服务、支付服务、金融业务服务等的供应商、服务合作商、第三方商家等，其中可能包括您的联络信息、订单信息、支付信息、地址信息等。
例如在您使用我们的外卖服务时，我们必须与配送服务合作商共享您的订单和配送信息，以保证订单的安全准确送达；又如我们需要将您的订单号和订单金额与第三方支付机构共享以实现确认您的支付指令并完成支付；将您的订单信息、必
要交易信息与第三方商家共享，以便其向您提供商品、服务及售后等。<strong>其中您的
联络信息和地址信息属于个人敏感信息，我们将努力通过采取“号码保护”、单据上的“地址隐藏”等合适的举措尽全力保护您的个人信息。
 
<br>4、保障安全和优化服务。</strong> 为了保障您在使用我们产品/服务时的安全，以及优化
我们的服务，我们可能会与我们的相关合作伙伴共享您的必要信息。
<strong>安全保障服务。</strong> 为了保障您的账号安全，避免您遭受欺诈、网络病毒、网络攻击 等风险，我们可能会用与我们安全保障的合作伙伴共享与您有关的必要设备信息、日志信息，以及您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，
预防安全事件的发生。
<strong>统计、优化服务。</strong> <u>经您授权，</u> 我们可能会与提供广告投放推广和统计分析的合作伙伴共享信息，用于相关合作伙伴可能会对上述信息进行汇聚分析，提供广告曝光监测或服务决策，为您提供更好的服务。但我们仅会向这些合作伙伴提供广告推广的覆盖面、有效性以及统计类等信息，而不会共享可以识别您身份的个人信息，否则我们会对这些信息进行去标识化处理以避免对您身份的识别。
<br><strong>5、二次号检查服务。</strong> 本业务中的手机号码二次号检查服务<strong>由第三方中国电信运营商、中国联通运营商、中国移动运营商提供，</strong> 为保障您的账号安全，我们可能会将您的<strong>手机号、手机号在佳玲闪送的绑定时间（精确到天）提供给此类服务提供商。</strong>
<br><strong>6、基于法定情形提供。</strong> 根据法律法规的规定、诉讼争议解决需要、您与我们签署的相关协议(包括<u>在线签署</u> 的电子协议及平台规则)或法律文件，或行政、司法等有权机关依法提出要求时，我们可能会共享您的个人信息。
<br>7、除了上述情况外，我们如果对其他任何公司、组织和个人共享您的个人信息，会再次征求您的明确同意或授权。
<strong>我们会通过应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方 SDK 会有所不同，一般包括第三方账号登录类、分享类、第三方支付类、地图导航类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。</strong> 请您知悉，为确保您能正常使用 SDK 的功能来接收消息，在部分移动设备厂商的设备上，我们接入的 Push SDK 可能会存在通过系统发送广播唤醒 App 自启动的行为，自启动行为仅会在征得您授权同意的情况下进行，如未征得您同意，则不会存在自启动行为。如果您还通过移动设备厂商（比如小米手机）的 widget（小组件）来使用最后100米 App，为了实现小组件的添加、删除和更新，App 可能会存在自启动或关联启动的行为，这是实现 widget 功能所必要的。
请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。
<br><br> <strong>（二）转让</strong>
<br>随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、重组、资产转让或类似的交易，<strong>如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则，我们将要求该公司、组织重新征求您的授权同意。</strong>
 
<br>除了上述情况，我们不会未经您的明确同意将您的个人信息进行转让。
<br><strong>（三）公开披露</strong>
<br>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才可能公开披露您的个人信息：
<br>1、根据您的需求，在<u>您明确</u> 同意的披露方式下披露您所指定的个人信息；
<br>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。
<br><strong>请注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或他
人的个人信息甚至个人敏感信息，如您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。
<br>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</strong>
<br>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意： 
<br>1、与国家安全、国防安全有关的；
<br>2、与公共安全、公共卫生、重大公共利益有关的；
<br>3、与犯罪侦查、起诉、审判和判决执行等有关的；
<br>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
<br>5、其他维护公共利益的情形，例如您的信用评价信息需要被公开/共享；
<br>6、您自行向社会公众公开的个人信息；
<br>7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。但是<u>您明确</u> 拒绝或者处理该信息<u>侵害您重大</u> 利益的除外；
<br>8、其他依法规定可以无需征得授权的情况。
<br><strong>根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。

<br><br>四、我们如何保存和保护您的个人信息
<br>（一）个人信息的保存
<br>1、保存期限：我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律法规有强制的留存要求，</strong> 例如《中华人民共和国电子商务法》要求商品
和服务信息、交易信息保存时间<u>自交易</u> 完成之日起不少于三年。我们判断个人信 息的存储期限主要参考以下标准并以其中较长者为准：完成与<u>您相关</u> 的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；保证我们为您提供服务 的安全和质量；您是否同意更长的留存期间；是否存在关于保留期限的其他特别 约定或法律法规规定。
 
<br><strong>在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。在您主动注销账号时，我们将根据法律法规的要求进行数据处理。</strong>
<br><strong>2、保存地域：</strong> 我们在<u>中华人民共和国境内</u> 收集和产生的个人信息，将 <strong>存储在中国境内。</strong>
基于本隐私政策中所载的目的，您的个人信息可能<strong>被传输至中国以外的国家或地区</strong> 进行处理，我们将根据法律法规要求履行相应流程和合规义务，以保护您的个
人信息安全，相关情形包括但不限于：
<br>（1）	基于法律法规的明确规定；
<br>（2）	在传输您的个人信息前，向您告知上述信息并征得您的授权同意；
<br>（3）	您主动发起的跨境预订、下单、交易等个人行为（如预订境外酒店、景区门票等）；
<br>除非符合法律法规规定的征得同意的例外情形外，我们将严格按照法律及监管要求，采用<strong>合理的方式向您明示告知所涉产品/服务的境外接收方的名称、联系方式、处理个人信息的目的、方式和种类、以及您享有的个人信息的权利和行使方式，并征得您的授权同意。</strong> 例如，在您预订境外酒店、门票等境外产品/服务时，
我们将会在您下单前通过订单填写或订单确认页面向您明示告知上述信息，并征得您的授权同意，具体的告知同意形式，以具体产品/服务的订单页面为准。 在向境外提供您的个人信息前，我们将通过开展数据出境安全评估、经专业机构进行个人信息保护认证、或与境外数据接收方签署网信部门制定的数据出境标准合同等方式，以符合法律、行政法规和国家网信主管部门规定。我们将要求境外数据接收方按照不低于中国法律要求的安全和保密措施保护您的个人信息安全并履行相应的个人信息保护义务。
<br><strong>3、终止运营：</strong> 如果发生终止运营等情形，我们将会至少提前 30 天通知您，并在
终止运营后对您的个人信息根据法律法规的要求进行处理。
<br><strong>（二）个人信息的保护措施 
<br> 1、数据安全措施</strong>
<br>为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。佳玲闪送通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如在您的浏览器与“服务”之间交换数据(如信用卡信息)时采用 SSL 加密保护技术；同时对网站本身提供 https 安全浏览方式；使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
<br>2、请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方
式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。
<br><strong>3、安全事件</strong>
<br>在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的
建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推
送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。

<br><br>五、您如何<u>管理您</u> 的个人信息
<br>您对您的个人信息享有以下权利。根据法律法规要求，在特定情形下，除您另有安排，您的近亲属为了自身的合法、正当利益，可以对您的相关个人信息行使以下权利。
<br>（一）您有权访问、更正、<u>删除您</u> 的个人信息，法律法规规定的例外情况除外。
您可以通过以下方式管理您的信息:
<br>个人信息查阅与管理：您可以通过“我的”-右上角点击进入“设置”-“隐私管理“，进行个人信息的查阅与管理。
<br><br><strong>账号信息</strong> ——您可以通过“我的”页面访问、<u>更正您</u> 账号中的个人资料及账号信息（身份认证信息除外）、支付信息、账号绑定信息等，也可以更改您的密码、
添加安全信息或进行第三方账号和银行卡的解绑等，您可以通过访问网页及 App在设置中执行此类操作。
<br><strong>地址信息</strong> ——您可以通过访问佳玲闪送 App 页面上“我的”-“设置”-“个人信息”-“收货地址”中随时添加、更改、删除您的收货地址信息（包括收货人姓名、性别、收货地址、电话号码等）。
<br><strong>搜索浏览信息</strong> ——您可以通过搜索栏点击删除、“我的”—“足迹”等方式访问
或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。
<br><strong>订单信息</strong> ——您可以通过“我的”页面访问“我的订单”页面查看您的所有已经完成、待付款、待售后、待评价的订单。您可以选择删除已完成的订单信息，但这样可能导致我们无法根据您的购买信息而准确提供相应的售后服务。
<br><strong>评价信息</strong> ——您可以通过“我的”页面中访问“我的订单”-“待评价”-“我
的评价”来访问、增加、更正、删除您的评价内容。
<br>
<br><strong>请您知悉，根据法律法规的要求及不同信息的技术条件需要，我们可能无法立即从系统中完全<u>删除</u> 您所希望删除的相应信息，在此之前，我们将根据法规要求对相应信息仅进行存储并采取必要的安全保护措施方面的处理。</strong>
<br>如果您无法通过上述链接管理这些个人信息，您可以随时通过本隐私政策中提供的反馈方式联系我们。我们将在 15 天内<u>回复您</u> 的访问请求。
<br><strong>（二）改变您授权同意的范围或撤回授权</strong>
<br>您可以通过在佳玲闪送 App 中删除信息、更改隐私设置或者在系统中关闭设备权限功能等方式改变同意范围或撤回您的授权。
您可以通过“我的”-右上角点击进入“设置”-“隐私管理”-“系统权限管理”中来操作管理及撤回您在 App 内设置的系统授权。
请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权已经开展的个人信息处理。
<br><strong>（三）注销账号</strong>
<br>您有权注销您的佳玲闪送账号，您可以通过访问 App“我的”页面右上角“设置”- “账号与安全”-“注销账号”中进行在线操作。<strong>您注销成功后，除法律法规及相应的国家标准另有规定外，我们将<u>删除</u> 您的个人信息或作匿名化处理。</strong>
如果您无法通过上述方式访问、更正或<u>删除您</u> 的个人信息以及注销账号，或者如果您认为最后100米存在违反法律的规定收集、使用您个人信息的情形，您可以通过我们的客服或通过本隐私政策提供的其他方式与我们取得联系。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并<u>验证您</u> 的身份后的 15 天内答复您的请求。对于<u>您合理</u> 的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
<br><strong>（四）自主管理控制营销信息及定向推送</strong>
<br><strong>消息推送—</strong> —如您不希望收到 App 页面上的消息通知，可以在首次安装 App 时拒
绝接收消息通知，或者在移动端操作系统中的“通知”中心关掉对应的通知功能。
<br><strong>个性化推荐</strong> ——如您不希望使用我们的智能推荐服务，可以自主选择按照距离、
好评、价格等不针对您个人特征的方式进行排序，也可以在推荐信息页面中通过 “不感兴趣”、“不想看”等功能进行精准反馈和实时关闭；还可以在“我的”页面“设置”-“隐私管理”-“个性化内容推荐”中进行自主管理控制。
<br><strong>个性化广告</strong> ——如您不希望接收个性化的广告，您可以在“我的”页面“设
置”-“隐私管理”-“个性化广告推荐”中自行控制开关，当您关闭后，您看到的广告相关性会降低，但广告不会消失。
<br><strong>推广/回访</strong> ——如您不希望接收我们的推广短信或电话回访，可通过信息中相应
的退订功能进行退订，或者在电话回访中明确表示拒绝。
<br><br><strong>六、未成年人的个人信息保护</strong>
 
<br>佳玲闪送非常重视对未成年人个人信息的保护。<strong>我们的网站和服务主要面向成人。如您为未成年人的，建议您请在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</strong>
<br>对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
<br><strong>如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集
了未成年人的个人信息，则会设法尽快对相关数据采取适当处理。</strong>

<br><br><strong>七、通知和修订</strong>
<br>为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整，对我们的基本情况、您的个人信息的处理目的方式种类和保存期限、<u>您管理</u> 个人信息的方式、我们的联系方式发生变更的，我们会进行及时更新。未经<u>您明确</u> 同意，我们不会<u>削减您依据</u> 本隐私政策所应享有的权利。
<br>我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒<u>您相关</u> 内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。

<br><br><strong>八、如何联系我们</strong>
<br>您可以通过以下方式与我们联系，我们将在 15 天内答复您的请求；
<br>1、您可以通过佳玲闪送网站（如 mhsj100m.com）/App 上提供的在线联系方式/客服系统与我们联系；
<br>2、您可以联系佳玲闪送的客<u>服电话</u> 进行反馈（13603158371）。
<br><br><strong>九、附录：
<br>1、个人信息：</strong>
以电子或者其他方式记录的与已识别或者可识别的自然人有关的
<br>各种信息，不包括匿名化处理后的信息。本隐私政策中可能涉及到的个人信息包括但不限于：个人基本信息（包括姓名、电话号码、性别、住址、生日等）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；网络身份标识信息（包括系统账号、IP 地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（包括银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；
 
<br>个人健康生理信息（包括病症、病历、病史等）；联系人信息（包括通讯录信息、好友列表等）；个人上网记录（包括网站浏览记录、点击记录等）；个人设备信息（包括唯一设备识别码等描述个人常用设备基本情况的信息）；个人位置信息
（包括行踪轨迹、精准定位信息、住宿信息等）。
<br><strong>2、敏感个人信息：是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。本隐私政策中可能涉及到的个人敏感信息包括：个人身份认证信息（包括身份证、军官证、护照、驾驶证、学生证等）；个人财产信息（银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；其他信息（行踪轨迹、通讯录信息、住宿信息、精准定位信息、医疗健康信息、生物识别信息等）；14 周岁以下的未成年人信息。</strong>
<br> 3、佳玲闪送：本政策中所指的“佳玲闪送”是指佳玲闪送旗下所有公司及其附属、关联公司（包括佳玲闪送网及未来可能成立的公司）。
<br>4、关联账号：您可以使用相同的手机号码登录佳玲闪送所提供的产品/服务，并授权使用您的佳玲闪送账号信息（如头像、昵称、收货地址），以便于我们基于关联账号向您提供一致的服务以及您进行统一管理。

        </div>
    </div>
</template>

<script >

    export default {
      methods:{
        goLogin(){
          this.$router.push('/login')
        },
      
      },
       
    }
</script>

<style  scoped>
   .box{
    width:1144px;
    min-height:660px;
    margin: 0 auto ;
    margin-bottom: 5px;
    background-color: gainsboro;
    position: relative;
    border: 1px solid black;
    padding: 0 20px 0 20px;
   }
   .box img{
    margin-top: -40px;
    width:40px;
    height: 40px;
    position: fixed;
   }
  .box h1{
    margin-left: 360px;
  }
  .box .top{
    margin-left: 600px;
  }
</style>