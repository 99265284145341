<template>
 <div class="page-box">
     <router-view></router-view>
  </div>
</template>

<script>



import './assets/css/reset.css'
import './assets/css/theme.css'
import './assets/css/global.css'
export default {
  name: 'App',
 

}
</script>
<style  scoped>
  .page-box{
    background-color:l;
  }
</style>