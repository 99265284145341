<template>
  <div class="bg-fa of">
  <AppHeader></AppHeader>
    <section id="index" class="container">
      <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">支付</span>
        </h2>
      </header>
      <!-- <ul>
        <li v-for="product in productList" :key="product.id">
          <a :class="['orderBtn', {current:payOrder.productId === product.id}]" 
            @click="selectItem(product.id)" 
            href="javascript:void(0);" >
            {{product.title}} 
            ¥{{product.price *3}}
          </a>
        </li>
      </ul> -->

      
      <div class="PaymentChannel_payment-channel-panel">
        <h3 class="PaymentChannel_title"> 
          选择支付方式 
        </h3>
        <div class="PaymentChannel_channel-options" >

          <!-- 选择微信 -->
          <div :class="['ChannelOption_payment-channel-option', {current:payOrder.payType === 'wxpay'}]"
          @click="selectPayType('wxpay')">
            <div class="ChannelOption_channel-icon">
              <img src="../assets/img/wxpay.png" class="ChannelOption_icon">
            </div>
            <div class="ChannelOption_channel-info">
              <div class="ChannelOption_channel-label">
                <div class="ChannelOption_label">微信支付</div>
                <div class="ChannelOption_sub-label"></div>
                <div class="ChannelOption_check-option"></div>
              </div>
            </div>
          </div>

          <!-- 选择支付宝 -->
          <!-- <div :class="['ChannelOption_payment-channel-option', {current:payOrder.payType === 'alipay'}]"
          @click="selectPayType('alipay')">
            <div class="ChannelOption_channel-icon">
              <img src="../assets/img/alipay.png" class="ChannelOption_icon">
            </div>
            <div class="ChannelOption_channel-info">
              <div class="ChannelOption_channel-label">
                <div class="ChannelOption_label">支付宝</div>
                <div class="ChannelOption_sub-label"></div>
                <div class="ChannelOption_check-option"></div>
              </div>
            </div>
          </div> -->

        </div>
      </div>

      <div class="payButtom">
        <el-button 
        :disabled="payBtnDisabled"
        type="warning" 
        round 
        style="width: 180px;height: 44px;font-size: 18px;"
        @click="toPay()">
          确认支付
        </el-button>
      </div>
    </section>

    <!-- 微信支付二维码 -->
    <el-dialog
      :visible.sync="codeDialogVisible"
      :show-close="false"
      @close="closeDialog"
      width="350px"
      center>
     <qriously :value="codeUrl" :size="300"/>
        <!-- <img src="../assets/img/code.png" alt="" style="width:100%"><br> -->
        使用微信扫码支付
    </el-dialog>
    <!-- <AppFooter></AppFooter> -->
  </div>
</template>

<script >
import productApi from '../api/product'
import wxPayApi from '../api/wxPay'
import orderInfoApi from '../api/orderInfo'

// 引入APPFooter组件
// import AppFooter from '../components/AppFooter.vue'
import AppHeader from '../components/AppHeader.vue'

export default {

  data () {
    return {
      payBtnDisabled: false, //确认支付按钮是否禁用
      codeDialogVisible: false, //微信支付二维码弹窗
      productList: [], //商品列表
      payOrder: { //订单信息
        productId: '', //商品id
        payType: 'wxpay' //支付方式
      },
      codeUrl: '', // 二维码
      orderNo: '', //订单号
      timer: null // 定时器
    }
  },
  // 自己加的内容
  
  components:{
    // AppFooter,
    AppHeader
  },
  created () {
    //获取商品列表
    productApi.list().then(response => {
      this.productList = response.data.productList
      this.payOrder.productId = this.productList[0].id
      this.$router.push('/appHeader')
      this.$router.push('/appFooter')
    })
  },
  
  methods: {
    //选择商品
    selectItem(productId) {
      console.log('商品id：' + productId)
      this.payOrder.productId = productId
      console.log(this.payOrder)
      //this.$router.push({ path: '/order' })
    },

    //选择支付方式
    selectPayType(type) {
      console.log('支付方式：' + type)
      this.payOrder.payType = type
      //this.$router.push({ path: '/order' })
    },

    //确认支付
    toPay(){
      this.payBtnDisabled = true

      //微信支付
      if(this.payOrder.payType === 'wxpay'){
          //调用统一下单接口
        wxPayApi.nativePay(this.payOrder.productId).then(response => {
          this.codeUrl = response.data.codeUrl
          this.orderNo = response.data.orderNo
          this.codeDialogVisible = true

          // 启动定时器
          // this.timer = setInterval(() => {
          //   this.queryOrderStatus()
          // }, 3000)
        })
      }
    },

    //关闭微信支付二维码对话框时启用“确认支付”按钮
    closeDialog(){
      console.log('close.................')
      this.payBtnDisabled = false
      console.log('清除定时器')
      clearInterval(this.timer)
    },


    // 查询订单状态
    queryOrderStatus() {

      orderInfoApi.queryOrderStatus(this.orderNo).then(response => {
        console.log('查询订单状态：' + response.code)

        // 支付成功后的页面跳转
        if (response.code === 0) {
          console.log('清除定时器')
          clearInterval(this.timer)
          // 三秒后跳转到订单列表
          setTimeout(() => {
            this.$router.push({ path: '/orders' })
          }, 3000)
        }
      })
    }
  }
}
</script>

<style scoped>
</style>