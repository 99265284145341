<template>
    <div class="box">
        <h1>最后100米用户服务协议</h1>
        <div><img @click="goLogin" src="./image/左箭头.png" alt=""></div>
        <div class="top">协议版本：【1.0】；发布/生效日期：【2023 年 4 月 26 日】</div>
        <br><br> <h5>欢迎 <u>您注册</u> 最后100米账号并使用最后100米的服务！</h5>
        
        <br> <div>本《最后100米用户服务协议》（以下简称“ <strong>本服务协议</strong> ”）是您与最后100米之间就注册最后100米用户账号及使用最后100米的各项服务等相关事宜所订立的协议。
        <strong> <u>为使用最后100米的服务，您应当仔细阅读并遵守本服务协议下的全部内容，特别是涉及免除或者责任限制的条款，该类条款可能以黑体加粗或加下划线的形式提示您重点注意</u> </strong> 。除非您已阅读并接受 <u>本服务</u> 协议所有条款，否则您将不能注册最后100米账号或使用最后100米的服务，如您不同意 <u>本服务</u> 条款的任意内容，请勿注册或使用最后100米的服务，并应立即停止注册程序。如您对 <u>本服务</u> 协议的内容（特别是涉及免除或者责任限制的条款）有任何疑义，可随时按照本服务协议中列明的联系方式与我们联系，我们将根据您的要求为您进一步解释和说明相关内容。<strong><u>如您勾选“我同意《最后100米用户服务协议》”并通过注册程序或其他任何方式使用或接受最后100米的任何服务，即视为您已阅读并同意本服务协议，自愿接受本服务协议的所有内容的约束。请您在决定注册或使用服务前再次确认您已知悉并完全理解本服务协议的所有内容。</u> </strong> </div>
        <br>
        <div><strong> <u>如果您未满 18 周岁，请务必在家长和其他法定监护人的陪同下阅读本服务协议，，并在进行注册、下单、支付等任何行为或使用最后100米的其他任何服务前，应事先征得您的家长和其他法定监护人的同意。</u> </strong> </div>
        <br>
        <div><u>本服务</u> 协议所述“服务”既包括您使用最后100米用户账号接受最后100米平台相关商家、个人所提供的服务，亦包括在某些特定的业务类别下您使用最后100米用户账号向其他商家、个人或其他主体提供服务的情形。无论“服务”属于上述何种情形， 本《最后100米用户服务协议》都将予以适用。 </div>
        <h4>一、协议的主体与范围 </h4>
        <div>【签约主体】 视您所使用的具体服务而定，“最后100米”是指最后100米旗下运营或提供您所使用的相关具体服务的相关主体的统称，您可在相关服务的具体服务条款或说明文件中查看上述主体的名称和信息。  “用户”是指使用美团相关服务的使用人，在本服务协议中更多称呼为“您”。 </div>
        <br>
        <div>【协议内容】 <u>本服务</u> 协议内容同时包括： </div>
        <br>
        <div>（1）	本《最后100米用户服务协议》文本
        <br>
           （2）	《最后100米平台隐私政策》 ( 链 接 地 址 : <a href="/#/privacy">:https://portal- portm.zuihouyibaimi.com/webpc/protocolmanage/privacy)</a> )：最后100米非常重视您的个人信息和隐私的保护，将按照《最后100米平台隐私政策》中所公布的政策在具体服务中进行隐私及个人信息的收集、存储、使用、披露等活动。您在注册最后100米账号前，应仔细阅读《最后100米平台隐私政策》； 

        <br> （3）	具体服务的服务条款：您的最后100米平台账号一经注册，即可凭该账号按规定享受最后100米平台上的各项丰富的服务。为更好地向您提供各项服务，最后100米平台针对具体的服务制定了专门的服务条款，供服务各方遵守。您可在【相关具体服务的网站、页面或应用程序】中查阅并下载此类专门服务条款。您在使用具体服务前，应仔细阅读该服务所适用的服务条款，您注册最后100米账号并利用最后100米账号使用具体服务即表示您已同时接受该等服务条款，并将受该等服务条款的约束； 

        <br> （4）	专项商品或服务规则。为更好地提升用户体验，最后100米在若干具体专项商品或服务中特别制定了详细的规则，供各方遵守。 

        <br> （5）	第三 <u>方服务</u> 相关的用户授权协议。为方便您在使用最后100米平台服务的同时，根据您自身需要，通过最后100米平台进行跳转等方式，自愿选择接受独立第三 <u>方服务</u> 商及部分关联第三 <u>方服务</u> 商所提供的服务，您将在使用该等第三方服务前对相应的用户授权协议予以同意确认，以作为您和最后100米平台之间就该等第三方服务的使用所涉及的相关事项的权利义务依据。 

        <br> 上述各项内容之间如存在不一致之处，以时间上最新发布的内容为准，发布时间相同的，以本款【协议内容】中所包含的组成部分的序号倒序排列为准。 
        <br>【协议遵守】<strong> <u>您理解并同意： </u> </strong>
        <br> （1）	<strong> <u>上述【协议内容】中所述的协议、政策、条款与规则构成本服务协议不可  分割的组成部分，共同适用于您所使用的最后100米平台服务。</u> </strong> 
 
        <br> （2）	<strong> <u>根据国家法律法规变化、运营需要或为提升服务质量的目的，最后100米将在必要的时候对上述各项协议、条款与规则不时地进行修改更新，并通过在网页或移 动客户端上发出公告、站内信等合理、醒目的方式向您进行提前通知，上述修改更新内容将在相关更新说明中指定的日期开始实施，通常情况下不短于发布之日后八个自然日。 </u> </strong>

        <br> （3）	<strong> <u>您应当及时查阅并了解相关更新修改内容，如您不同意相关更新修改内容 ，可停止使用相关更新修改内容所涉及的服务，此情形下，变更事项对您不产生 效力；如您在上述更新修改内容实施后继续使用所涉及的服务，将视为您已同意各项更新修改内容。 </u> </strong>

        <br> 【适用平台】本服务协议适用于网页端、移动客户端（包括 iOS、安卓及已有或未来将新增的任何其他移动客户端）等各类平台或媒介中最后100米所提供的各项服务。您知悉并理解，为享受上述相关服务，您必须自行提供相关上网设备（如：个人电脑、手机、平板电脑或其他设备）并自行负担相关网络使用和商品服务支付所产生的有关费用。 
        </div>

        <div>
          <h4>二、账号的注册 </h4>
          【注册资料】您应遵循诚实信用、合法善意的原则，向最后100米提交相关注册资料，相关注册资料应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公序良俗、信息真实等原则，不应提交任何违法或不良信息，相关资料如有变动，您应及时更新。<strong> <u>如果因您所提供的注册资料不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您将承担相应的法律责任及不利后果。</u> </strong> 
          <br> <strong> <u>您已知悉，在最后100米账号注册过程中，因安全性、内部管理等原因，部分初始注册 资料可能将无法再次更改。因此，您应谨慎核查此部分注册资料，确保正确填写。</u> </strong> 
           <br> <br> 【民事行为能力】<strong> <u>您知悉并承诺，您具有完全民事权利能力和行为能力或虽不具有完全民事权利能力和行为能力但已经过您的家长和其他法定监护人同意并由您  的家长和其他法定监护人代理注册及使用最后100米的各项服务。若您不具备前述相适应的民事行为能力即进行账号注册的，则您及您的家长和其他法定监护人应  依照法律规定承担因此而导致的一切后果。 </u> </strong>
 

             <br> 【真实身份】因国家法律法规、监管要求及特定门类的服务（如金融服务等） 需要，您有可能需要在注册或使用该等服务的特定环节中填写真实的身份信 息，并需通过相关验证流程后方可使用服务，<strong> <u>如您填写的身份信息不完整、不真实或未通过验证，将导致您无法使用该服务、损害自身、他人利益或造成其他不利后果，该等后果将由您予以承担。</u> </strong> 



           <br>  【注册信息保护】最后100米将对您所提交的注册信息予以保护，不会将其披露给任何非关联的第三方，<strong>  <u>除非： </u></strong>

            <br><br> （1）	相关法律法规或国家机关要求； 

            <br>（2）	最后100米发生相关合并、分立、收购或资产转移；或 

            <br> （3）	为提供相关服务所必须的 

            <br>对于您所提交的注册信息中涉及个人信息的内容，最后100米将严格按照《最后100米平台隐私政策》的规定进行收集、处理和使用。 



            <br>  <br>    【账号数量】除因历史原因、业务整合等最后100米所认可的特殊情况外，最后100米原则上只允许您使用一个最后100米用户账号。如有证据证明或最后100米有理由相信 <u>您存在</u> 不当注册或不当使用多个最后100米账号的情形，最后100米可将相关账号信息进行合并或采取其他合理措施，如因您不当注册或不当使用给最后100米及相关方造成损失的，您还应承担相应的赔偿责任。此外，出于相关业务需要，最后100米也可对同一用户的多个账号或相关信息进行合并，如该等合并将对您的权益产生实质影响，最后100米将在进行上述合并前事先征得您的同意。 

            <br><strong> <u>【提示】因为无法进行安全验证，如果您使用境外手机号码注册最后100米账号，将无法享受包括但不限于部分优惠活动在内的账号权益。 </u> </strong>
           
           <h4>三、账号的使用 </h4>

            <br> 【账号登录】您可以使用注册账号时填写登记的并获得系统审核通过的账号密码登录最后100米平台。为了您的使用便利，最后100米将适时提供指纹识别、人脸识别等更为安全便捷的登录方式，最后100米可根据相关法律法规、监管要求、用户体验、风险控制等因素对登录方式予以适时调整或增减，或在具体业务门类的用户服务条款等规定中予以进一步细化或调整。 
 

            <br>【账号使用】您应对您账号项下的所有行为（包括但不限于在线签署任何协 议，浏览、购买、支付、点评、上传、发布、输入任何内容）所产生的一切后
果负责，通过您的账号所发生的上述各项行为将视为您本人的真实意思表示。 



<strong> <u>最后100米提示您，您通过自身账号在接受最后100米的各项服务中所上传、发送的任何内容都应具有合法来源，如相关内容涉及任何第三方的合法权益，应事先获得相应的许可。如最后100米收到涉及您的相关举报或投诉，最后100米可采取相关法律法规所要求或允许的方式，向相关争议方、相关部门提供包括账号在内的必要的信息，以便解决纠纷争议，保护正当权利人的合法权益。 </u> </strong>



<br>  【账号借用】<strong> <u>为保证相关账号安全，未经最后100米的书面同意，您不应将最后100米平台上所注册的账号借给他人使用，否则您应当承担由此产生的全部责任，最后100米平台保留拒绝提供相应服务、冻结或收回注册账号或终止本服务协议的权利，并可要求您对最后100米所承受的损失予以赔偿。</u> </strong> 



<br>【安全义务】<strong> <u>如您发现账号存在安全问题，请您立即联系最后100米予以调查处理，否则最后100米对潜在损失的产生或扩大不承担任何责任。 </u> </strong>

	

<br><br>最后100米特别提示您，您应该妥善 <u>保管您</u> 的账号和密码，当您使用完毕最后100米的服务后，您应安全退出。此外，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意最后100米有关防范诈骗犯罪的提示。<strong> <u>您知悉并同意，如您在账号信息的保管上、相关上网设备的网络安全维护上存在任何过失导致账号丢失、泄露，您应对此所产生的任何后果负责，最后100米对此不负任何责任，如最后100米因此产生相关支出，最后100米将有权向您予以追偿。 </u> </strong>



<br>【限制冻结】您知悉并同意，在符合法律法规的规定，或经国家机关要求的前提下，最后100米有权对您的注册账号进行限制或冻结，在该等情况下，您可能无法继续登陆或使用您的注册账号。 


 <h4>四、用户行为规范与责任承担 </h4>     


 【用户义务】您知悉并承诺，在使用最后100米所提供的服务的过程中，您应遵守相关法律法规，不应从事如下违反法律法规的规定，影响正常服务提供或损害他人合法利益的行为： 

<br> （1）	不应利用最后100米平台或相关服务危害国家安全、破坏政治稳定、泄露国家秘密，不侵犯国家、社会、集体利益和第三方的合法权益，不从事违法犯罪活动，不设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组，不利用网络发布涉及实施诈骗，制作或者销售违禁物品、管制物品以及其他违法犯罪活动的信息； 

<br>（2）	不应制作、发布、复制、查阅和传播、存储、链接下列信息：反对宪法所确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；捏造、散布谣言，侵犯他人权利，扰乱经济、社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；宣扬恐怖主义、极端主义的；违背当地风俗习惯的；含有法律、行政法规禁止的其他内容的 

<br>（3）	不应从事下列危害计算机信息网络安全的活动：对网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加的；对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的；使用软件或硬件等方式窃取他人口令、非法入侵他人计算机系统；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的活动； 

<br>（4）	不应擅自复制和使用网络上未公开和未授权的文件。除相关著作权人明示同意，不应在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动； 

<br>（5）	不应私自传播广告信息或以“刷单”等不正当方式帮助他人提升评价或利用评价权利对其他用户、商户实施威胁、敲诈勒索； 

<br>（6）	不应因对最后100米相关服务的使用行为导致最后100米卷入政治和公共事件； 

<br>（7）	不应通过侵犯第三人合法权益、作弊、扰乱系统、实施网络攻击、恶意套现、刷信誉、批量注册、用机器注册最后100米平台账户、用机器模拟客户端等手段进行交易或参加最后100米或其所授权的第三方发布的任何活动； 

<br>（8）	未经最后100米事先书面许可，不应自行或授权、协助任何第三方非法抓取最后100米所展示的任何内容（“非法抓取”是指采用未经最后100米认可的程序或者非正常浏览等技术手段获取内容数据的行为）。 
 

<br><br>【责任承担】<strong> 
<u>如您在使用最后100米服务过程中涉及上述行为中的一项或多项，则需要对自己的行为承担法律责任。</u> </strong> 承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在最后100米首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任等损失（含诉讼费、律师费等相关法律程序费用）后，您应立即给予最后100米等额的赔偿。此外，根据具体违法违规情形，最后100米有权作出独立判断，立即暂停或终止对您提供部分或全部服务，包括锁定、注销、删除帐号等措施。同时，<strong> <u>您同意，如因您的行为造成他人经济损失的，最后100米可出于公共利益保护、消费者保护、商业利益保护等原则，在合法合规的前提下，从您的钱袋宝账户（如有）中划扣相应款项，您同意并授权、委托最后100米进行上述划扣操作。 
    您同意，如因您的行为造成他人经济损失的，最后100米可出于公共利益保护、消费者保护、商业利益保护等原则，在合法合规的前提下，从您的钱袋宝账户（如有）中划扣相应款项，您同意并授权、委托最后100米进行上述划扣操作。 
</u> </strong>


<br><br>您知悉并理解，如果最后100米发现您的上述违法违规行为，依据相关法律法规的规定，最后100米有权或有义务立即停止相关服务，删除或屏蔽相关违规信息、服务评价，并视情况进行调查取证，保存有关记录，或向国家有关机关举报。同时， 最后100米有权自主删除、屏蔽含有该内容的任何数据信息。最后100米将根据国家相关法律法规要求，对依法删除、屏蔽的数据信息予以记录、保存。 



<br><br>【广告促销】您理解并同意：为向您提供更为细致、贴心的服务，在经过您的事先确认后，最后100米或最后100米授权、认可的第三方商家、广告商可能通过 <u>您注册</u> 时填写的手机号码或者电子邮箱向您发送您可能感兴趣的商品服务的广告宣传信息、促销优惠等商业性信息，其方式和范围可不经向您特别通知而变更；如果您不愿意接收此类信息，则您有权通过最后100米平台提供的相应的退订方式进行退订。 



<br><br><strong> <u>您理解并同意，对上述的相关广告信息，您应审慎判断其真实性和可靠性，除法律法规明确规定外，您应对依该广告信息进行的交易负责。</u> </strong> 



<br><br>【使用目的】<strong> <u>除非在特定服务条款或规则中您与最后100米另有约定或最后100米另行予以书面同意，您将确保本服务协议下的服务仅为您个人非商业性质的使用。未经最后100米书面同意，您不得使用未经最后100米授权的任何插件、外挂或第三方工具对本服务协议下的服务进行干扰、破坏、修改或施加其他影响。 </u> </strong>



<h4>五、知识产权 </h4>
 

【知识产权】您理解并知悉，除另有书面声明以外，以下信息和内容的知识产权（包括但不限于专利权、著作权、商标权及商业秘密）归最后100米所有： 

<br><br>1.	在最后100米相关服务中所提供的内容和信息（包括但不限于软件、技术、程序、网页、文字、图片、图像、地图、图标、音频、视频、图表、版面设计、电子文档、数据资料等）； 

<br>2.	最后100米用于提供上述内容和信息的基础设施和平台（包括但不限于软件、网站、应用程序等）； 

<br>3.	在最后100米提供相关服务中所使用的各项商标、商业形象、商业标识、技术诀窍、标语、文案等； 

<br>4.	最后100米平台服务的开发、运营、维护等过程中产生的所有数据和信息。 



<br><br><strong> <u>最后100米所拥有的上述权利及所提供服务中所包含的任何内容的知识产权均受到法律保护，未经最后100米事先书面许可，您承诺不应且不应允许或协助任何人以任何形式（包括但不限于通过任何机器人、蜘蛛、截屏等程序或设备）进行使用、出租、出借、分发、展示、复制、修改、链接、转载、汇编、发表、出版、抓取、监视、引用或创造相关衍生作品。 </u> </strong>



<br><br>【用户生成内容】您理解并同意，您在使用最后100米提供的服务时上传、提交、存储或发布的内容（包括但不限于文字、图片、视频、音频、动画等）均由您原创或已获合法授权。您通过最后100米上传、提交、存储或发布的任何内容的知识产权归属您或原始著作权人所有，您的上传、提交、存储或发布行为不会侵犯他人的知识产权或其他合法权益。 



<br><br>您知悉、理解并同意，<strong> <u>您一旦接受本服务协议，即表明您主动将上述内容的非专属、可转让的财产性权利，如著作权（包括但不限于：复制权、发行权、出租权 、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），在全世界范围内永久、免费、独家且不可撤销地授权给最后100米及其关联公司，最后100米及其关联公司可基于该等授权使用上述内容（包括但不限于用于商业用途）或向第三方自主进行任何必要的转授权。该等授权、转授权的使用场景包括但不限于当前或其他任何网站、应用程序、产品或移动终端设备等，且最后100米及其关联公司或最后100米及其关联公司所授权许可的第三方可通过对上述授权内容进行修改、复制、改编、翻译、汇编或制作，形成衍生产品。在不违反相关法律法规的强制性规定、尊重相关原始授权内容的知识产权的基础上，该等衍生产品的相关知识产权归最后100米及其关联公司或最后100米所授权许可的第三方所有。  </u> </strong>




<br><br><strong> <u>您确认并同意授权最后100米及其关联公司以自己的名义或委托专业第三方针对有关您上传、提交、存储或发布的内容（含衍生作品）的侵权行为进行独立自主的维权并获得全部赔偿。维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等。最后100米及其关联公司有权对维权事宜做出独立决策并予以实施。 </u> </strong>



<br><br><strong> <u>您同意，因您通过最后100米上传、发布的任何内容导致的知识产权侵权问题，您将承担全部责任；如最后100米及其关联公司，或最后100米授权的其他服务提供方因第三方知识产权维权而产生损失，您将等额赔偿。</u> </strong> 



<br><br>本服务协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于用户在最后100米平台发布的任何受著作权法保护的作品内容，无论该内容形成于本服务协议签订前还是本服务协议签订后。最后100米及最后100米转授权、许可的相关方有权将您在最后100米平台发表的产品使用体验、产品讨论或图片进行使用或者与其他人合作使用，使用范围包括但不限于网站、电子杂志、杂志、刊物、海报、公众号文章、微信小程序等。 



<br><br>【软件使用】在使用最后100米相关客户端的过程中，您可能需要下载特定软件。为了改善用户体验、修复漏洞、保障安全性等考虑，最后100米有权对软件进行更新，您应该将相关软件更新至最新版本，否则最后100米将不保证您能正常使用相关软件。 



<br><br><strong> <u>如果您从未经最后100米授权的第三方获取软件或与该软件名称相同的安装程序，最后100米无法保证该软件能够正常使用，由此造成的相关损失，最后100米不予负责。</u> </strong> 



<br><br>【软件使用禁止性规定】除非最后100米书面许可，您在使用最后100米的软件时不得从事下列任一行为： 

<br><br>（1）	删除、编辑或遮挡软件及其副本上关于著作权、商标或其他权利标识或标记的信息； 
 
<br>（2）	复制、发布、出售或出租软件或其任何部分； 

<br>（3）	对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试挖掘、提取软件的源代码； 

<br>（4）	其他对软件、软件所产生的数据所进行的任何增加、删减、变动的任何行为，包含制作、利用、授权各种第三方外挂、插件、系统进行上述行为。 



<h4> 六、协议的终止 </h4>



<br>【协议终止情形】本服务协议于以下任一情形下终止： 

<br><br>（1）	最后100米有权依据本服务协议约定，收回、注销用户账号，此情形下，本服务协议于账号收回、注销之日终止； 

<br>（2）	在满足最后100米平台公示的账号注销条件时，您通过网站自助服务或最后100米的客服注销最后100米的用户账号，本服务协议于账号注销之日终止。您理解并同意，您已认真阅读、认可《最后100米隐私政策》及其附件一《用户注销协议》，并已了解、同意相关用户注销流程及注销后的权利义务安排； 

<br>（3）	最后100米有权根据自身商业安排经过合理的提前通知终止全部最后100米平台服务，本服务协议于最后100米平台全部服务依法定程序及方式终止之日终止。 



<br><br><strong> <u>本服务协议终止后，最后100米将无法继续向您提供任何服务或履行任何其他义务，包括但不限于为您保留或向您披露其原最后100米账号中的任何信息，向您或第三方转发任何未曾阅读或发送过的信息等。 </u> </strong>



<br><br>本服务协议的终止不影响第五条知识产权条款以及其他根据其内容应继续有效的条款的有效性，也不影响本服务协议终止前各方的相关权利和义务，包括但不限于守约方依据本服务协议向违约方追究相应的违约责任。 



<h4>七、通知与送达 </h4>

   
 
<br><br>您知悉并认可，最后100米可视情况通过下列任意一种或几种方式向您通知重要信息： 

<br>（1）	向 <u>您注册</u> 时所提交的电子邮箱地址发送电子信息； 

<br>（2）	向 <u>您注册</u> 时所提交的手机号码发送电子信息； 

<br>（3）	向您提供的实际地址进行寄送纸质载体信息； 

<br>（4）	在网站或客户端显著位置刊登电子信息； 

<br>（5）	向最后100米网站或相应客户端的账户的站内信或其他即时通信客户端发送电子信息。 



<br><br>上述电子信息在发送成功或刊登完成后即视为送达。相关纸质载体的发送以相关邮寄凭证上的邮寄日期后的第五个自然日视为送达。 



<br><br>上述送达方式同样可适用于相关仲裁或司法程序（含起诉、审理、执行等各阶段）。 

<br><br><strong> <u>您应当保证所提供的联系方式是准确、有效的，并进行适时更新，如因提供的联系方式不准确或怠于更新等不可归责于最后100米的原因，导致相关通知、文件、文书无法送达或及时送达，您将自行承担由此可能产生的法律后果。</u> </strong> 



<h4>八、不可抗力或其他免责事由 </h4>



<br><br>【不可抗力】您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务协议下的服务发生中断或终止。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于信息网络设备维护、信息网络连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为、法律法规变动、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务等行为。出现上述情况时，最后100米将努力在第一时间与相关部门配合，及时进行修复，但是由此给您造成的损失，最后100米在法律允许的范围内免责。 

   
 
<br><br>【其他免责事由】您理解并同意，在法律允许的范围内，最后100米对以下事由所导致的服务中断或终止不承担责任： 

<br>（1）	受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏； 

<br>（2）	用户或最后100米的电脑软件、系统、硬件和通信线路出现故障； 

<br>（3）	用户操作不当； 

<br>（4）	用户通过非最后100米授权的方式使用本服务； 

<br>（5）	其他最后100米无法控制或合理预见的情形。 



<br><br>【信息真实性】最后100米提示您，在使用本服务的过程中，您可能会遇到网络信息或其他用户行为带来的风险，该等风险包含他人匿名或冒名的含有威胁、诽谤、令人反感、非法、误导、欺骗等任何信息或行为所造成任何心理、生理上的伤害或经济损失。请您仔细甄别，并在遇到上述相关不法行为时及时向最后100米或有关机关举报或投诉。最后100米将对相关内容开展日常检查并在收到举报或投诉时进行相应处理，但<strong> <u>请您注意，最后100米不对非最后100米所发出或刊登的任何信息的真实性 、适用性、合法性承担责任，也不对因第三方侵权行为给您造成的损害负责。</u> </strong> 



<br><br>【责任限制】除非另有明确书面说明，<strong> <u>在符合法律法规规定的情况下，最后100米不对其网站和客户端上的信息、内容、材料、产品或服务做任何形式的明示或默示的声明或担保。</u> </strong> 



 <h4>九、管辖、法律适用与争议解决 </h4>

 <br><br>本服务协议的成立、生效、履行、解释与纠纷解决，适用中华人民共和国大陆地区法律法规，并且排除一切冲突法规定的适用。 

 <br><br>如因某项具体服务中的产品或服务问题导致您与最后100米间出现纠纷，您同意，该等纠纷将由适用于该项具体服务的服务条款或规则中所规定的争议解决地的有管辖权人民法院受理。就本服务协议而言，如您因本服务协议文本与最后100米产生争议纠纷，<strong> <u>您同意交由本服务协议签订地有管辖权人民法院受理。本服务协议签订地为北京市朝阳区。</u> </strong> 



<h4>十、其他</h4> 
 

<br><br>【关键词】本服务协议各条款前所列关键词仅供帮助理解条款主旨及快速定位查询条款所用，不能代替条款的任何内容，亦不作为解释条款的依据，最后100米建议您仔细阅读各条款的具体表述，以维护您的合法权益。 



<br><br>【可分性】如果本服务协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。 



<br><br>如您对本服务协议有任何问题或建议，请在工作时间联系最后100米客服部门（联系方式：13603158371）。 



<br><br>再次感谢您的耐心阅读！ 

     </div>

    
    </div>
</template>

<script >

    export default {
      methods:{
        goLogin(){
          this.$router.push('/login')
        }
      },
       
    }
</script>

<style  scoped>
   .box{
    width:1144px;
    min-height:660px;
    margin: 0 auto ;
    margin-bottom: 5px;
    background-color: gainsboro;
    position: relative;
    border: 1px solid black;
    padding: 0 20px 0 20px;
   }
  .box h1{
    margin-left: 360px;
  }
  .box img{
    margin-top: -40px;
    width:40px;
    height: 40px;
    position: fixed;
   }
  .box .top{
    margin-left: 600px;
  }
</style>