<template>
    <div class="box">
      <!-- 搜索区域 -->
      <div class="search">
        <img @click="goBack" src="../components/image/左箭头.png" alt="">
        <input placeholder="可乐" type="text">
        <button @click="goSearch" >搜索</button>
    </div>
    
    <div class="body"></div>
    </div>
</template>

<script>
    export default {
        methods:{
            goBack(){
                this.$router.push('/homePage')
            },
            goSearch(){
                this.$router.push('/search01')
            }
        }
        
    }
</script>

<style  scoped>
.box{   
    width:1144px;
    height:660px;
    margin: 0 auto ;
    margin-bottom: 5px;
    /* background-color: gainsboro; */
    position: relative;
    border: 1px solid white;
    text-align: center;
}
.search{
    position: absolute;
    margin: 10px auto;
    margin-left: 250px;
   
}
.search img{
    float: left;
    width: 100px;
    height: 50px;
}
.search img:hover{
    background-color: blue;
}

.search button:hover{
    background-color: red;
}
.search input{
    height: 47px;
    width:468px;
    border-radius:5px 0px 0px 5px;
}
.search button{
    font-size: 14px;
    width:100px;
    height: 51px;
    border-radius:0px 5px 5px 0px;
}

.body{
    width: 100%;
    height: 615px;
    margin-top: 45px;
    background-color: white;
}
</style>