<template>
    <div class="all">
       <h1>最后100米</h1>
       <div class="color"></div>
       <div>购物车</div>
       <div class="shop" v-for="item in product" :key="item.id">
          <img :src="item.photo" alt="">
          <div>{{item.name}}</div>
           
          <div class="num">
            <button @click="unAddQuality(item.id)">-1</button>
            <li>{{ item.count }}</li>
            <button @click="addQuality(item.id)">+1</button>
           
          </div>
          
          <div style="color:red">{{item.price.toFixed(2)}}</div>
          <div class="shan"><a href="#" @click="reserve(item.id)">删除</a></div>
          <hr>
       </div>
       <div class="color"></div>

   
        <div>订单总价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{getTotalPrice.toFixed(2)}}</div>
              <div style="font-size:10px;" class="price">只限今日特价</div>
        <button class="button" @click="goCar" type="">购买</button>

  
    </div>
</template>

<script>
import {mapState} from 'vuex'


    export default {
        data(){
            return{
               
                
            }
        },
        computed: {
            ...mapState(['product']),
           
            getTotalPrice(){
                let sum=0//eslint-disable-line no-unused-vars
                this.product.forEach((item)=>{
                    sum+=item.price*item.count
                })
                return sum
            }
        },
        methods:{
            // ...mapMutations(['getTotalPrice']),
            
            goCar(){
                this.$router.push('/index')
            },
           
            reserve(id){
                // console.log(id);
                let newArray = this.product.filter((item)=>{
                    return item.id != id
                })
                console.log(newArray);
                this.$store.commit('removeGood',newArray)              
            },
            addQuality(id){
                // console.log(this.product,id);
                let newProduct=this.product.filter((item)=>{return item.id==id})
                // console.log(newProduct);
                newProduct.forEach((item)=>{
                    item.count+=1
                    this.sum=item.count*item.price
                })
            },
            unAddQuality(id){
                let newProduct=this.product.filter((item)=>{return item.id==id})
                // console.log(newProduct);
                newProduct.forEach((item)=>{
                    item.count-=1
                    if(item.count<=0){
                        return this.sum,item.count=0
                    }
                    else{
                       this.sum=item.count*item.price
                    }
                   
                })

            },
        },
        
    }
</script>

<style  scoped>
  div{
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left:100px ;
  }
  h1{
    margin-left: 100px;
    /* margin-top: 30px; */
    /* margin-bottom: 30px; */
    font-size: 100px;
    }
   .color{
    width: 109%;
    height: 50px;
    margin-left: -100px;
    background-color: gainsboro;
    
   } 

  
   img{
    float: left;
    width: 200px;
    height: 150px;
    margin-right: 100px;
   }
   .num{
    width: 100px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 20px;
    margin-left: 900px;
    display: flex;
    justify-content:space-between
   }
   .shan{
    margin-left: 1100px;
    margin-top: -30px;
   }
   .price{
    float: right;
    font-size: 1px!important;
    margin-top: -30px;
    margin-right: 50px;
    /* margin-left: 900px; */
   }
   .button{
    width: 100px;
    height: 100px;
    font-size:30px;
    background-color: red;
    border-radius: 5px;
    margin-left: 900px;
   }

</style>