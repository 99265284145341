// 创建应用程序的路由器
import Vue from 'vue'
import VueRouter from 'vue-router'
// 此时就可以在Vue实例中配置路由器了
Vue.use(VueRouter)

// 引入组件
import Index from '../views/index'
import Orders from '../views/Orders'
import Download from '../views/Download'

import HomePage from '../views/homePage.vue'
import Search from '../views/search.vue'
import Search01 from '../components/search01.vue'
import PinLei from '../components/pinLei.vue'
import ShangDian from '../components/shangDian.vue'
import Login from '../views/login.vue'
import Serve from '../components/serve.vue'
import Privacy from '../components/privacy.vue'
import Car from '../views/Car.vue'
import JiaLing from '../components/JiaLing.vue'
import See100 from '../components/See100.vue'




// 创建并暴露一个路由器
export default new VueRouter({
    routes:[
        {path: '/',redirect: '/homePage'},
        { path: '/orders',component: Orders},
        { path: '/download', component: Download},
        { path: '/homePage', component: HomePage},
        {path: '/index',component:Index},
        {path: '/appFooter',redirect:'/index'},
        {path: '/search',component:Search},
        {path: '/pinLei',component:PinLei},
        {path: '/shangDian',component:ShangDian},
        {path: '/search01',component:Search01},
        {path: '/login',component:Login},
        {path: '/serve',component:Serve},
        {path: '/privacy',component:Privacy},
        {path: '/car',component:Car},
        {path: '/see100',component:See100},
        {path: '/jialing',component:JiaLing},
    ]
})
